/* eslint-disable no-case-declarations */
import { useRef, useState, useCallback } from 'react'

import Link from '@/components/link-component'
import SearchNavIcon from '@/components/search/nav-icon'
import NavContainer from '@/components/nav-container'
import HeaderDropdownMenu from '@/components/header-dropdown-menu'
import HeaderRichMenu, { HeaderRichMenuContent } from '@/components/header-rich-menu'
import CtaLink, { SizeType, buttonStyles } from '@/components/ui/cta-link'
import StaggerList, { StaggerListItem } from '@/components/ui/stagger-list'
import HeaderBanner from '@/components/header-banner'
import Disclosure from '@/components/ui/disclosure'
import DynamicAction from '@/components/ui/dynamic-action'
import { Experience } from '@ninetailed/experience.js-react'
import { mapNtExperiences } from '@/utils/ninetailed'
import Hamburger from '@/icons/bwi/hamburger.svg'
import Close from '@/icons/bwi/close.svg'
import routes from '@/constants/routes'

import LogoHorizontal from '@/icons/logo-horizontal.svg'
import ArrowDown from '@/icons/bwi/angle-down.svg'

import { useHasScrolled } from '@/utils/dom'
import colors from '@/constants/colors'
import { AnyLinkType, flattenLinkGroups, useDefaultHeader } from '@/utils/header'
import { cx } from '@/utils/strings.ts'

function MobileDisclosureArrow(isOpen: boolean) {
  return (
    <span className={cx(`w-[28px] h-[28px] [&_svg]:!text-indigoBlue !transition-transform`, isOpen && `rotate-180`)}>
      <ArrowDown />
    </span>
  )
}

export function HeaderComponent(props) {
  const { backgroundColor = colors.primaryBlue, ctaNavItems, mainNavItems, showSearch, headerBanners } = props

  const mainNavLinks = flattenLinkGroups(mainNavItems)
  const ctaNavLinks = flattenLinkGroups(ctaNavItems)

  const updateMobileDownloadLink = (ctaLink) => {
    if (ctaLink?.link?.url?.endsWith('/download/')) {
      return {
        ...ctaLink,
        link: {
          ...ctaLink,
          url: `${ctaLink.link.url}#downloads-mobile`,
        },
      }
    }
    return ctaLink
  }
  const transformCustomMobileLinks = (link: AnyLinkType) => {
    switch (link.sys.contentType.sys.id) {
      case 'componentCtaLink':
        return updateMobileDownloadLink(link)
      case 'headerDropdownMenu':
        return {
          ...link,
          links: link.fields.links.map((nestedLink) => updateMobileDownloadLink(nestedLink)),
        }
      case 'headerRichMenu':
        return {
          ...link,
          cards: link.fields.cards.map((card) => ({
            ...card,
            links: card.fields.links.map((nestedLink) => ({
              ...nestedLink,
              link: updateMobileDownloadLink(nestedLink.fields.link),
            })),
          })),
          links: link.fields.links,
        }
      default:
        break
    }
    return link
  }

  const AreMainNavItemsEmpty = (mainNavItems?.length || 0) === 0
  const mobileMainNavLinks = flattenLinkGroups(!AreMainNavItemsEmpty ? mainNavItems : ctaNavItems).map(
    transformCustomMobileLinks
  )

  const [isDrawerOpen, setIsDrawerOpen] = useState(false)
  const closeDrawer = () => setIsDrawerOpen(false)
  const [topRef, hasScrolled] = useHasScrolled()
  const headerRef = useRef()

  let ctaBtnSolidCount = 0
  let ctaBtnSolidCountMobile = 0

  return (
    <>
      <div ref={topRef as never} className="h-0" />
      <HeaderBanner banners={headerBanners} />
      <header
        className={cx(
          `sticky top-0 left-0 z-50 transition duration-300`,
          hasScrolled && `shadow-lg`,
          backgroundColor === colors.white && `bg-white text-primaryBlue`,
          backgroundColor === colors.primaryBlue && `bg-primaryBlue text-white [*>a]:text-white  dark:bg-gray-800`,
          backgroundColor === colors.white && `bg-white text-primaryBlue dark:bg-gray-800 dark:text-white)`,
          isDrawerOpen && `z-[10000]`
        )}
        ref={headerRef}
      >
        {/* Mobile Nav */}
        <nav
          data-testid="bw-mobile-header"
          className="relative max-w-7xl mx-auto px-5 flex justify-between items-center py-3 lg:hidden"
        >
          <div className="w-40">
            <Link to={routes.index} title="Bitwarden homepage">
              <LogoHorizontal
                className={cx(
                  backgroundColor === colors.primaryBlue && `text-white`,
                  backgroundColor === colors.white && `text-primaryBlue dark:text-white`
                )}
              />
            </Link>
          </div>
          <div className="flex gap-3 items-center">
            {showSearch && <SearchNavIcon />}
            {mobileMainNavLinks.length + ctaNavLinks.length > 0 && (
              <button
                type="button"
                className={cx(
                  'w-12 h-12 flex cursor-pointer transition-colors duration-200 hover:text-gray-300 dark:text-white',
                  isDrawerOpen && `pointer-events-none`
                )}
                data-name="bwi-hamburger"
                onClick={() => {
                  setIsDrawerOpen(!isDrawerOpen)
                }}
              >
                {/* we really should make sure our icons are uniform in size / canvas area */}
                {!isDrawerOpen ? <Hamburger className="w-12" /> : <Close className="m-auto w-6" />}
              </button>
            )}
          </div>

          {/* Mobile Dropdown */}
          <NavContainer
            position="right"
            isDrawerOpen={isDrawerOpen}
            drawerBreakpoint="lg"
            className="!bg-gray-100 !pb-16"
            onRequestClose={closeDrawer}
            headerRef={headerRef}
            showOverlay={false}
            fullWidth
          >
            <div style={{ minHeight: `calc(100vh - var(--nav-container-drawer-offset)` }} className="flex flex-col ">
              <StaggerList
                className={`flex-1 list-none pb-[var(--nav-container-drawer-offset)]`}
                isVisible={isDrawerOpen}
              >
                {mobileMainNavLinks.map((link) => {
                  if (!link) return null
                  switch (link.sys.contentType.sys.id) {
                    case 'componentCtaLink':
                      return (
                        <StaggerListItem key={link.sys.id}>
                          <DynamicAction
                            className={cx(
                              'no-underline !bg-transparent block !py-6 !px-6 w-full duration-200 text-xl !text-indigoBlue hover:text-indigoBlue !border-0 !border-b-[1px] !border-gray-300 rounded-none'
                            )}
                            onClick={closeDrawer}
                            {...(link.fields as any)}
                          />
                        </StaggerListItem>
                      )
                    case 'headerDropdownMenu':
                      return (
                        <StaggerListItem
                          key={link.sys.id}
                          className={cx(
                            `p-0 border-b-[1px] border-b-gray-300`,
                            `[&_button]:p-6 [&_button]:w-full [&_button]:rounded-none [&_button]:!bg-gray-100 [&_button]:items-center [&_button]:justify-between`
                          )}
                        >
                          <Disclosure
                            heading={
                              <div className="flex items-center justify-between text-xl text-indigoBlue">
                                {link.fields.title}
                              </div>
                            }
                            ArrowIcon={MobileDisclosureArrow}
                          >
                            <ul className="flex flex-col">
                              {link.fields.links.map((nestedLink) => (
                                <li className="list-none" key={nestedLink.sys.id}>
                                  <DynamicAction
                                    className="no-underline !block bg-transparent py-6 !pl-10 font-normal text-xl !text-indigoBlue border-t-[1px] border-gray-200"
                                    onClick={closeDrawer}
                                    {...(nestedLink.fields as any)}
                                  />
                                </li>
                              ))}
                            </ul>
                          </Disclosure>
                        </StaggerListItem>
                      )
                    case 'headerRichMenu':
                      return (
                        <StaggerListItem
                          key={link.sys.id}
                          className={cx(
                            `p-0 border-b-[1px] border-b-gray-300 [&_button]:p-6 [&_button]:w-full [&_button]:rounded-none [&_button]:bg-gray-100! [&_button]:items-center [&_button]:justify-between`
                          )}
                        >
                          <Disclosure
                            className="hover:!bg-transparent"
                            heading={
                              <div className="flex items-center justify-between text-xl text-indigoBlue">
                                {link.fields.title}
                              </div>
                            }
                            ArrowIcon={MobileDisclosureArrow}
                          >
                            <div className="px-6 pb-6">
                              <HeaderRichMenuContent
                                links={link.fields.links}
                                cards={link.fields.cards}
                                isCondensed
                                onLinkClick={closeDrawer}
                              />
                            </div>
                          </Disclosure>
                        </StaggerListItem>
                      )
                    default:
                      return null
                  }
                })}
              </StaggerList>
              {ctaNavLinks?.length > 0 && !AreMainNavItemsEmpty && (
                <div className="sticky bottom-[var(--nav-container-drawer-offset)] translate-y-[62px] left-0 w-full bg-white flex flex-row flex-wrap items-center justify-between p-6">
                  {ctaNavLinks.map((ctaLink) => {
                    if ((ctaLink as any)?.fields.style === buttonStyles.btnSolid) {
                      ctaBtnSolidCountMobile += 1
                    }
                    const isAltColor = ctaBtnSolidCountMobile % 2 === 0
                    return (
                      <DynamicAction
                        key={ctaLink.sys.id}
                        className={cx(
                          'no-underline !text-base !py-0',
                          (ctaLink as any).fields?.style === buttonStyles.btnSolid && `!text-white h-auto px-4 !py-2`,
                          (ctaLink as any).fields?.style === buttonStyles.btnOutlined &&
                            `!text-primaryBlue h-auto px-4 !py-2`,
                          (ctaLink as any).fields?.style === buttonStyles.btnPlain && `!text-primaryBlue !p-1`,
                          (ctaLink as any).fields?.style === buttonStyles.btnArrow && `!text-primaryBlue !p-1`,
                          (ctaLink as any).fields?.style === buttonStyles.btnSolid &&
                            (!isAltColor
                              ? `!bg-indigoBlue !border-indigoBlue`
                              : backgroundColor === colors.primaryBlue
                                ? `!bg-white !text-indigoBlue border-indigoBlue`
                                : `!bg-primaryBlue`)
                        )}
                        onClick={closeDrawer}
                        {...(ctaLink.fields as any)}
                      />
                    )
                  })}
                </div>
              )}
            </div>
          </NavContainer>
        </nav>

        {/* Desktop Nav */}
        <nav
          data-testid="bw-desktop-header"
          className="relative hidden max-w-8xl mx-auto py-2 px-9 justify-between gap-4 lg:flex"
        >
          <div className="flex items-center">
            <div className="w-36 2xl:w-44 py-3 mr-6 xl:mr-10 2xl:mr-12">
              <Link to={routes.index}>
                <LogoHorizontal
                  className={cx(
                    'text-white',
                    backgroundColor === colors.white && `!text-primaryBlue`,
                    'dark:!text-white'
                  )}
                  title="Bitwarden homepage"
                />
              </Link>
            </div>
            <div className="mr-2 xl:mr-5">
              {mainNavLinks?.length > 0 && (
                <ul className="flex gap-4 xl:gap-6 items-center list-none text-base xl:text-lg">
                  {mainNavLinks.map((link) => {
                    const { fields, sys } = link
                    const linkFields = { ...fields, style: buttonStyles.btnPlain }
                    switch (sys.contentType.sys.id) {
                      case 'componentCtaLink':
                        return (
                          <li key={link.sys.id}>
                            <DynamicAction
                              className={cx(
                                '!text-base xl:!text-lg no-underline !bg-transparent border-0 p-0 transition-colors duration-200',
                                backgroundColor === colors.primaryBlue &&
                                  `text-white hover:!text-gray-300 dark:hover:!text-tealBlue`,
                                backgroundColor === colors.white &&
                                  `text-primaryBlue hover:text-indigoBlue dark:text-white dark:hover:!text-tealBlue`
                              )}
                              {...(linkFields as any)}
                            />
                          </li>
                        )
                      case 'headerDropdownMenu':
                        return (
                          <li key={link.sys.id}>
                            <HeaderDropdownMenu {...link.fields} backgroundColor={backgroundColor} />
                          </li>
                        )
                      case 'headerRichMenu':
                        return (
                          <li key={link.sys.id}>
                            <HeaderRichMenu {...link.fields} backgroundColor={backgroundColor} />
                          </li>
                        )
                      default:
                        return null
                    }
                  })}
                </ul>
              )}
            </div>
            {showSearch && <SearchNavIcon className={backgroundColor === colors.white && 'hover:text-indigoBlue'} />}
          </div>
          <div className="flex gap-5 items-center">
            {ctaNavLinks.map((ctaLink) => {
              switch (ctaLink.sys.contentType.sys.id) {
                case 'componentCtaLink':
                  const isBtnSolid = ctaLink.fields.style === buttonStyles.btnSolid
                  const isBtnOutlined = ctaLink.fields.style === buttonStyles.btnOutlined
                  const isBtn = isBtnSolid || isBtnOutlined
                  if (isBtnSolid) {
                    ctaBtnSolidCount += 1
                  }
                  const isAltColor = ctaBtnSolidCount % 2 === 0

                  return (
                    <CtaLink
                      key={ctaLink.sys.id}
                      {...ctaLink.fields}
                      to={ctaLink.fields.link.fields.url}
                      size={ctaLink.fields.size as SizeType}
                      className={cx(
                        `no-underline !text-base text-center xl:!text-lg xl:!leading-5`,
                        `focus:rounded-sm focus:outline focus:outline-1 focus:outline-white`,
                        !isBtn && `focus:outline-offset-4`,
                        backgroundColor === colors.primaryBlue && `text-white hover:!text-gray-300`,
                        backgroundColor === colors.white && `text-primaryBlue hover:!text-indigoBlue`,
                        `dark:text-white dark:hover:!text-tealBlue`,
                        // General Button style
                        // - Mobile to XL: Display as Link
                        // - XL and above: Display as Button
                        isBtn &&
                          `!bg-transparent border-0 !p-0 hover:!bg-transparent xl:!px-6 xl:!py-3 xl:border-0 xl:focus:rounded-3xl dark:text-white dark:hover:!text-tealBlue`,
                        // Button Outlined style
                        isBtnOutlined &&
                          backgroundColor === colors.primaryBlue &&
                          `xl:!border-white xl:text-white xl:hover:!bg-white xl:hover:!text-indigoBlue`,
                        isBtnOutlined &&
                          backgroundColor === colors.white &&
                          'hover:!border-transparent xl:!border-indigoBlue xl:text-indigoBlue xl:hover:!bg-indigoBlue xl:hover:!text-white',
                        // Button Solid style with primaryBlue background
                        isBtnSolid &&
                          !isAltColor &&
                          backgroundColor === colors.primaryBlue &&
                          `!border-2 hover:!border-transparent xl:!bg-indigoBlue xl:hover:!bg-transparent xl:!border-indigoBlue xl!border-2 xl:hover:!border-2 xl:hover:!border-white xl:hover:!text-white`,
                        // Button Solid style with white background
                        isBtnSolid &&
                          !isAltColor &&
                          backgroundColor === colors.white &&
                          `hover:!border-transparent xl:!bg-primaryBlue xl:!border-primaryBlue !text-white xl:hover:!bg-transparent !border-2 xl:hover:!border-primaryBlue xl:hover:!text-primaryBlue`,
                        // Alternative Button Solid style with primaryBlue background
                        isBtnSolid &&
                          isAltColor &&
                          backgroundColor === colors.primaryBlue &&
                          `hover:!border-transparent xl:!bg-white xl:!border-white xl:text-indigoBlue xl:hover:!bg-transparent !border-2 xl:hover:!border-white xl:hover:!text-white xl:focus:!text-indigoBlue xl:focus:!border-white`,
                        // Alternative Button Solid style with white background
                        isBtnSolid &&
                          isAltColor &&
                          backgroundColor === colors.white &&
                          `xl:!bg-indigoBlue xl:!border-indigoBlue xl:!text-white xl:hover:!bg-transparent xl:hover:!border-indigoBlue xl:hover:!text-indigoBlue xl:focus:!text-indigoBlue`,
                        // Dark Mode Button Solid style
                        isBtnSolid &&
                          !isAltColor &&
                          `dark:!border-transparent dark:xl:!bg-transparent dark:xl:!border-2 dark:xl:!border-white dark:xl:hover:!bg-white dark:xl:hover:!text-gray-800 dark:xl:hover:!border-white`,
                        isBtnSolid &&
                          isAltColor &&
                          `dark:xl:!bg-white dark:xl:!border-white dark:xl:!text-indigoBlue dark:xl:hover:!bg-transparent dark:xl:hover:!border-white dark:xl:hover:!text-white`
                      )}
                    >
                      {ctaLink.fields.label}
                    </CtaLink>
                  )
                default:
                  return null
              }
            })}
          </div>
        </nav>
      </header>
    </>
  )
}

export default function Header(props): JSX.Element {
  const Loader = useCallback(
    () => (
      <div className="bg-primaryBlue opacity-95 sticky top-0 left-0 z-50 ">
        <div className="animate-pulse bg-primaryBlue w-full h-[72px] lg:h-[64px] xl:h-[68px] relative hidden max-w-8xl mx-auto px-9 justify-between gap-4 lg:flex">
          <div className="flex items-center">
            <div className="w-36 2xl:w-44 py-3 mr-6 xl:mr-10 2xl:mr-12">
              <LogoHorizontal className="text-white" />
            </div>
          </div>
        </div>
      </div>
    ),
    []
  )

  return (
    <Experience
      {...props}
      id={props.id}
      experiences={mapNtExperiences(props.nt_experiences)}
      component={HeaderComponent}
      loadingComponent={Loader}
      passthroughProps={{ headerBanners: props.headerBanners }}
    />
  )
}
